<template>
  <div>
    <t-modal v-model="showDetails">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Update Appointment</h4>
        <div class="flex justify-end items-center">
          <div class="flex flex-col items-end space-y-1">
            <span class="text-xs font-semibold text-gray-600"
              >Start Time: {{ selectedAppointment.startTime }}</span
            >
            <span class="text-xs font-semibold text-gray-600"
              >End Time {{ selectedAppointment.endTime }}</span
            >
            <span class="text-xs font-semibold text-gray-600"
              >Date {{ selectedAppointment.date }}</span
            >
          </div>
        </div>
        <div>
          <p class="text-sm font-semibold capitalize text-primary">
            {{ selectedAppointment.client.firstName }}
            {{ selectedAppointment.client.lastName }}
          </p>
          <p class="text-sm mt-4 font-normal text-gray-500">
            {{ selectedAppointment.description }}
          </p>
        </div>
        <div class="flex mt-5 w-full justify-between items-center">
          <div class="w-9/12">
            <t-select
              class="w-9/12"
              v-model="selectedAppointment.status"
              :options="['Pending', 'Fulfilled', 'Cancelled', 'Missed']"
            >
            </t-select>
          </div>
          <div>
            <t-button
              @click="updateAppointment"
              class="flex space-x-3 items-center"
              ><span>Update</span>
              <spinner v-if="updatingAppointment"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <page-title>Appointment</page-title>
    <page-subtitle>Here's your Appointments list</page-subtitle>
    <div class="mt-5">
      <div class="flex rounded space-x-2 items-center justify-between mb-5">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            v-model="search"
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
      </div>
      <div class="bg-white p-5 rounded">
        <div class="mb-5 bg-white">
          <div
            class="tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"
          >
            <span
              @click="changeActiveTab('all')"
              :class="
                `py-3 px-5 cursor-pointer ${
                  activeTab === 'all' ? 'bg-primary text-white ' : ''
                } rounded-tl`
              "
              >All</span
            >
            <span
              @click="changeActiveTab('fulfilled')"
              :class="
                `py-3 px-5 cursor-pointer ${
                  activeTab === 'fulfilled' ? 'bg-primary text-white ' : ''
                }`
              "
              >Fulfilled</span
            >
            <span
              @click="changeActiveTab('pending')"
              :class="
                `py-3 px-5 cursor-pointer ${
                  activeTab === 'pending' ? 'bg-primary text-white ' : ''
                }`
              "
              >Pending</span
            >
            <span
              @click="changeActiveTab('cancelled')"
              :class="
                `py-3 px-5 cursor-pointer ${
                  activeTab === 'cancelled' ? 'bg-primary text-white ' : ''
                }`
              "
              >Cancelled</span
            >
          </div>
          <div v-if="loading" class="flex items-center justify-center mt-5">
            <spinner color="text-primary" />
          </div>
          <div v-else class="client-list grid grid-cols-1 mt-5">
            <div class="flex flex-col w-full">
              <div class="overflow-x-scroll">
                <table class="table-auto min-h-full min-w-full">
                  <thead
                    class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                  >
                    <tr>
                      <th class="py-3 px-3">Title</th>
                      <th class="py-3 px-3">Client's Name</th>
                      <th class="py-3 px-3">Date</th>
                      <th class="py-3 px-3">Time</th>
                      <th class="py-3 px-3">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="appointment in appointmentList"
                      :key="appointment._id"
                      @click="showUpdateModal(appointment)"
                      class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                    >
                      <td class="px-3 py-4">{{ appointment.title }}</td>
                      <td class="px-3 py-4">
                        {{ appointment.client.firstName }}
                        {{ appointment.client.lastName }}
                      </td>
                      <td class="px-3 py-4">
                        {{ new Date(appointment.date).toDateString() }}
                      </td>
                      <td class="px-3 py-4">{{ appointment.startTime }}</td>
                      <td class="px-3 capitalize py-4 rounded">
                        <span
                          class="px-2 py-1 rounded text-white"
                          :class="
                            appointment.status === 'pending'
                              ? 'bg-yellow-400 text-white'
                              : appointment.status === 'fulfilled'
                              ? 'bg-green-400'
                              : appointment.status === 'cancelled'
                              ? 'bg-red-400'
                              : 'bg-amber-600'
                          "
                          >{{ appointment.status }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="mt-10 flex flex-wrap">
            <span
              v-for="l in meta.pages"
              :class="
                `p-2 ${
                  meta.offset / (l - 1) === (meta.limit || 0)
                    ? 'text-white bg-primary'
                    : 'text-black'
                } py-1 cursor-pointer rounded shadow m-2`
              "
              @click="getAllAppointments((l - 1) * meta.limit)"
              :key="l"
            >
              {{ l }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import Spinner from '../../components/Utils/Spinner'
import appointmentApi from '../../api/appointment'

export default {
  name: 'AppointmentList',
  components: { PageTitle, PageSubtitle, Spinner },
  data: () => ({
    showDetails: false,
    search: '',
    appointments: [],
    meta: {},
    loading: false,
    updatingAppointment: false,
    activeTab: 'all',
    selectedAppointment: {
      client: {}
    }
  }),
  created() {
    this.getAllAppointments(0)
  },
  computed: {
    appointmentList() {
      if (this.activeTab === 'all') {
        return this.appointments.filter(c =>
          this.search
            .toLowerCase()
            .split(' ')
            .every(v => {
              const data =
                c.title + ' ' + c.client.firstName + ' ' + c.client.lastName
              return data.toLowerCase().includes(v)
            })
        )
      }
      if (this.activeTab !== 0) {
        return this.appointments
          .filter(c => c.status === this.activeTab)
          .filter(c =>
            this.search
              .toLowerCase()
              .split(' ')
              .every(v => {
                const data =
                  c.title + ' ' + c.client.firstName + ' ' + c.client.lastName
                return data.toLowerCase().includes(v)
              })
          )
      }
      return this.appointments
    }
  },
  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab
    },
    async getAllAppointments(offset) {
      this.loading = true
      const res = await appointmentApi.appointment().getAll({ offset })
      this.appointments = res.data.data
      this.meta = res.data.meta
      this.meta.pages = Math.ceil(this.meta.total / this.meta.limit)
      this.loading = false
    },
    async updateAppointment() {
      this.updatingAppointment = true

      // pending, fulfilled, cancelled, missed

      const res = await appointmentApi
        .appointment()
        .updateAppointment(
          { appointmentId: this.selectedAppointment._id },
          this.selectedAppointment.status.toLowerCase()
        )

      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.updatingAppointment = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })

      this.getAllAppointments()
      this.updatingAppointment = false
      this.showDetails = false
    },
    showUpdateModal(n) {
      this.selectedAppointment = n
      this.selectedAppointment.status = 'Pending'
      this.showDetails = true
    }
  }
}
</script>

<style></style>
